import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import Main from "../components/main"
import { Box, Heading, Styled } from "theme-ui"
import SEO from "../components/seo"

/*
 * Esta es la forma abreviada de declarar un componente
 * Fijate que usa {} por que tiene variables dentro
 * Luego hacer un return.
 * Mira comentarios en archivo layout
 *
 */
export default ({ data, props }) => {

  const mapData = data.wordpressWpSmbpMap

  const tags = mapData.tags_map

  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} description="Mapa de projectos. Proyecto finalista o ganador" />
      <Header>
        <Heading as={`h1`}>{ mapData.title }</Heading>
        <Box
          as={`ul`}
          sx={{
            mt: 2,
            pl: 2,
            listStyle: `square`,
          }}
        >
          <Styled.li><Styled.a href={mapData.acf.fb_web} rel="noopener noreferrer">Sitio web de la biblioteca</Styled.a></Styled.li>
          <Styled.li>{mapData.acf.fb_mail}</Styled.li>
          <Styled.li>{mapData.acf.fb_address}, {mapData.acf.fb_address_2}</Styled.li>
        </Box>
      </Header>
      <Main>
        <Box
          as={`article`}
          className="text-proyect"
          sx={{
            maxWidth: `42rem`,
            pl: [3, 3, 3, 4, 4],
            pr: [3, 3, 3, 0, 0],
            mt: 4,
            ".ambits": {
              display: [`block`, `inline-block`],
              mb: 0,
              pl: 1,
            },
            ".link-to-ambit-archive": {
              display: [`block`, `inline-block`],
            },
            ".link-to-ambit-archive:not(:last-child)": {
              "&:after": {
                content: `", "`
              }
            }
          }}
        >
          <Heading as={`h2`}>{ mapData.acf.fb_project_name }</Heading>
          <Styled.ul>
            <Styled.li>Año de presentación: {mapData.acf.fb_presentation_year}</Styled.li>
            <Styled.li>Ámbitos:
              <ul className="ambits">
                {tags &&
                  tags.map( tag => (
                    <li className="link-to-ambit-archive" key={tag.slug}><Styled.a as={Link} to={`/ambito/${tag.slug}`} rel="bookmark">{tag.name}</Styled.a></li>
                  ))
                }
              </ul>
            </Styled.li>
          </Styled.ul>
          {mapData.acf.fb_description &&
            <Fragment>
              <Heading as={`h3`}>Descripción del proyecto</Heading>
              <div dangerouslySetInnerHTML={{ __html: mapData.acf.fb_description }} />
            </Fragment>
          }
          {mapData.acf.fb_goals &&
            <Fragment>
              <Heading as={`h3`}>Objetivos del proyecto</Heading>
              <div dangerouslySetInnerHTML={{ __html: mapData.acf.fb_goals }} />
            </Fragment>
          }
          {mapData.acf.fb_activities &&
            <Fragment>
              <Heading as={`h3`}>Actividad, acciones o servicios desarrollados hasta el momento</Heading>
              <div dangerouslySetInnerHTML={{ __html: mapData.acf.fb_activities }} />
            </Fragment>
          }
          {mapData.acf.fb_outputs &&
            <Fragment>
              <Heading as={`h3`}>Resultados obtenidos</Heading>
              <div dangerouslySetInnerHTML={{ __html: mapData.acf.fb_outputs }} />
            </Fragment>
          }
          {mapData.acf.fb_contributions &&
            <Fragment>
              <Heading as={`h3`}>Contribución del proyecto a la cohesión e inclusión social y a minimizar los desequilibrios sociales</Heading>
              <div dangerouslySetInnerHTML={{ __html: mapData.acf.fb_contributions }} />
            </Fragment>
          }
          {mapData.acf.fb_entities &&
            <Fragment>
              <Heading as={`h3`}>Agentes que participan y/o dan soporte al proyecto</Heading>
              <div dangerouslySetInnerHTML={{ __html: mapData.acf.fb_entities }} />
            </Fragment>
          }
        </Box>
      </Main>
    </Layout>
  )

}

export const query = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    wordpressWpSmbpMap(id: { eq: $id }) {
      title
      id
      slug
      tags_map {
        name
        slug
      }
      acf {
        fb_activities
        fb_address
        fb_address_2
        fb_address_map_final {
          lat
          lng
        }
        fb_ccaa
        fb_contributions
        fb_description
        fb_entities
        fb_goals
        fb_mail
        fb_outputs
        fb_presentation_year(formatString: "YYYY")
        fb_project_name
        fb_web
        fb_video
        fb_website_project
      }
    }
  }
`
